.logo-card:hover .logo-title {
    display: block;
  }
  
  .logo-title {
    background-color: white; 
    display: none;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 2px 2px 2px rgba(116, 116, 116, 0.5); /* This adds a dark shadow for contrast */
    font-weight: bold;
    color: 'black'; /* Or any color you want */
    text-align: center;
    width: 100%;
  }
  
  .logo-container {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  /* You may need to adjust the styles for the image to ensure it covers the card properly */
  .logo-container img {
    transition: opacity 0.3s;
    width: 100%;
    height: auto;
    display: block;
  }
  
  .logo-card:hover img {
    opacity: 0.3;
  }
  
  .logo-card:hover .logo-title {
    display: block;
    color: rgb(25, 25, 196)
  }
  