/* Adjusting font size for all checkboxes globally */

  
/* For small screens */
@media (max-width: 768px) {
    .ant-checkbox-wrapper {
      font-size: 11px; /* Smaller font size */
    }
    .ant-checkbox-wrapper .ant-checkbox-inner {
      width: 13px; /* Even smaller width for small screens */
      height: 13px; /* Even smaller height for small screens */

    }
    .ant-checkbox-wrapper .ant-checkbox + span {
        padding-left: 0.3em; /* Remove left padding */
        padding-right: 2; /* Remove right padding if needed */
      }
      .ant-tree .ant-tree-node-content-wrapper {
        font-size: 12px; /* Adjust as needed */
      }
      
  }
  
  @media (max-width: 768px) {
    .custom-checkbox .ant-checkbox-wrapper {
      font-size: 14px; /* Adjusted for smaller screens */
    }
  }
  