

@media (max-width: 600px) {
    .tab-menu {
        flex-direction: row; /* Adjust to column layout for screens <= 600px */
    }

    /* Optional: Adjust menu item styles for smaller screens */
    .tab-menu .ant-menu-item {
        text-align: center; /* Center-align the text for a cleaner look on small screens */
        padding: 4px;
        font-size: 10px;
    }
}
