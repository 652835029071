@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.custom-table {
    padding: 0px 10px;

}

.custom-table .ant-table-thead > tr > th {
    background-color: #2F83C5; /* Change this to your preferred header color */
    color: white; /* Optional: Change text color */
    font-weight: normal; /* Optional: Change text weight */
    font-size: 14px;
    height: 55px;
    font-family: 'Roboto', sans-serif !important;
    
   
}

.custom-table .ant-table-tbody > tr > td {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;
    pointer-events: none; 

}

.custom-table a {
    font-family: 'Roboto', sans-serif !important;
    color: black; 
    text-decoration: none; 
}

.custom-table .ant-table-thead > tr > th.ant-table-column-has-sorters:hover,
.custom-table .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: #4b98d3 !important; /* Maintain the header color */

}


.custom-table .ant-table-thead > tr > th .ant-table-column-sorters:hover svg {
    fill: black; /* This changes the SVG fill color */
}

.ant-collapse {
    border: none;
}

/* Add this CSS to your stylesheet */
.ant-collapse-header {
    color: white !important;
    background-color: #2F83C5 !important;
    border-radius: 10px !important;
    border-top: 10px;
    border: none;
    font-weight: 700;
}


.ant-collapse-item {
    border: none;
    border-radius: 10px !important;
    margin: 10px;
    font-family: 'Roboto', sans-serif !important;
  
}




@media only screen and (max-width: 600px) {
    .custom-table .ant-table {
        overflow-x: auto;
    
    }
    .custom-table .ant-table-cell {
        font-size: 8px; /* Set your desired font size */
      }
      .custom-table .ant-table-thead > tr > th {
        font-size:8px;
    
    }
  }
  
