/* Ensure the dropdown menu takes full viewport width */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.custom-mega-menu {
    position: static !important;
    
  }
  
  
  .custom-mega-menu .dropdown-menu {
    width: 100vw; /* Set the width to 100% of the viewport width */
    left: 0 !important; /* Align to the left edge of the viewport */
    right: 0 !important; /* Align to the right edge of the viewport */
    margin: 0; /* Remove any default margins */
    padding: 50px 0px;
    border: 0;
    
    border-top: 1px solid #e5e5e5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow to the bottom */

  }

  .custom-mega-menu .dropdown-toggle {
    color: #2F83C5 !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    font-family: "Roboto", sans-serif !important; 
  }

  
  /* Optional: Adjust this as per your need for the contents */
  .custom-mega-menu .container {
    max-width: none !important;
  }

  .custom-mega-menu .mega-menu-column .dropdown-header {
    font-weight: bold;
    font-size: 16px;
    color : black;
    display:rgb(197, 35, 35);
    align-items: center;

  }
  
  .custom-mega-menu .dropdown-arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-right: 5px;
  }
  .custom-mega-menu .dropdown-divider {
    margin: 0px 15px;
    margin-bottom: 5px;
    
  }

  .mega-menu-column {
    max-width: 550px;
    font-size: 16px;
    margin: 0px;
    margin-right: 16px;
  }

  .menu-item {
    line-height: 1.5;
    transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out; /* Smooth transition for color and text-decoration */
  }
  
  .menu-item:hover, .menu-item:focus {
    color: #0056b3; /* Blue color on hover/focus */
    text-decoration: underline; /* Add underline on hover/focus */
  }
